/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, type PropsWithChildren } from 'react'
import { LosseLayout, useLocation, useLosseLayout } from '@ubo/losse-sjedel'
import Footer from './Footer'
import Header from './Header/Header'
import ScrollProgress from '../elements/ScrollProgress'

export default function Layout({ children }: PropsWithChildren<{}>) {
  return (
    <LosseLayout>
      <Content>{children}</Content>
    </LosseLayout>
  )
}

function Content({ children }: React.PropsWithChildren<{}>) {
  const { isScrollable, setScrollable } = useLosseLayout()
  const location = useLocation()

  useEffect(() => {
    if (isScrollable) document.body.style.overflow = 'auto'
    else document.body.style.overflow = 'hidden'
  }, [isScrollable])

  useEffect(() => {
    if (!isScrollable) setScrollable(true)
  }, [location.pathname])

  return (
    <>
      <Header />
      <ScrollProgress />
      <main className="min-h-screen-dynamic pt-[75px] 2xl:pt-[102px]">{children}</main>
      <Footer />
    </>
  )
}
