import { type LoaderData, useLoaderData } from '@ubo/losse-sjedel'
import type { Component_Scrollprogress, Component_Scrollprogress_Pages } from '~/graphql/types'

interface ScrollProgressLoaderData extends Omit<LoaderData, 'rest'> {
  rest: {
    ScrollProgress: {
      scrollprogress: Component_Scrollprogress
    }
  }
}

export default function useScrollProgress(): Component_Scrollprogress_Pages['databaseId'][] {
  const { rest } = useLoaderData<ScrollProgressLoaderData>()

  return rest.ScrollProgress.scrollprogress.pages.map((p) => p.databaseId)
}
