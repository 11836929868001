/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { LosseLink, LossePlaatjie, useLoaderData, useLocation, useLosseLayout, useNavigate } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from '../Header'
import clsx from 'clsx'

const HeaderMega: React.FC = () => {
  const { isScrollable, setScrollable } = useLosseLayout()
  const { header } = useLoaderData<HeaderLoaderData>()
  const location = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(-1)
  const activeHeaderMenu = header?.menuHeader[isSubmenuOpen]
  const navigate = useNavigate()

  useEffect(() => {
    if (isScrollable && isOpen) setScrollable(false)
    if (!isScrollable && !isOpen) setScrollable(true)
  }, [isOpen])

  useEffect(() => {
    if (isOpen) setIsOpen(false)
  }, [location.pathname, location.hash, location.search])

  return (
    <div>
      <button
        type="button"
        className="z-10 gap-2 h-10 min-w-[unset] flex items-center text-center hover:underline hover:underline-offset-8 decoration-site-accent"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Open menu"
      >
        <div>
          <div className="mx-auto my-[5px] h-[2px] w-[24px] bg-white" />
          <div className="mx-auto my-[5px] h-[2px] w-[24px] bg-white" />
          <div className="mx-auto my-[5px] h-[2px] w-[24px] bg-white" />
        </div>
        <div className="text-white text-lg font-semibold normal-case block">Menu</div>
      </button>
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            initial={{ y: '-100%' }}
            animate={{ y: 0 }}
            exit={{ y: '-100%' }}
            transition={{ duration: 0.3 }}
            className="fixed inset-x-0 top-0 z-50 h-screen bg-site-base"
          >
            <div
              key={`${isSubmenuOpen}dda`}
              className={clsx(
                activeHeaderMenu?.submenu ? 'bg-white' : 'bg-site-base',
                'animate-fade-left animate-ease-in-out animate-duration-500 lg:hidden flex flex-col items-start pt-20 px-10 h-full w-full'
              )}
            >
              <div
                role="button"
                aria-label="Close menu"
                onClick={() => setIsOpen(!isOpen)}
                className={clsx(
                  activeHeaderMenu?.submenu ? 'text-site-base' : 'text-white',
                  'flex justify-end mb-7 w-full absolute right-10 top-8'
                )}
              >
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_977_1218)">
                    <path
                      d="M18 1.0605L16.9395 0L9 7.9395L1.0605 0L0 1.0605L7.9395 9L0 16.9395L1.0605 18L9 10.0605L16.9395 18L18 16.9395L10.0605 9L18 1.0605Z"
                      fill="currentColor"
                    />
                  </g>
                </svg>
              </div>

              <div className={clsx(activeHeaderMenu?.submenu ? 'hidden' : 'flex flex-col', 'h-full max-lg:w-full')}>
                <ul className="flex flex-col">
                  {header?.menuHeader?.map((edge: any, index: number) => {
                    return edge?.submenu?.length > 0 ? (
                      <li key={index} onClick={() => setIsSubmenuOpen(index)} className="cursor-pointer mb-5 sm:mb-10">
                        <p className="text-2xl items-center flex gap-2 transition-all text-white hover:underline hover:underline-offset-8 decoration-site-accent font-light uppercase">
                          {edge?.link?.title}
                          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
                            <path
                              fill="currentColor"
                              fill-rule="evenodd"
                              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8L4.646 2.354a.5.5 0 0 1 0-.708"
                            />
                          </svg>
                        </p>
                      </li>
                    ) : (
                      <li key={index} className="mb-5 sm:mb-10 last:mb-8 sm:last:mb-[103px]" onClick={() => setIsOpen(!isOpen)}>
                        <LosseLink
                          to={edge?.link?.url || '/'}
                          className="text-2xl transition-all text-white hover:underline hover:underline-offset-8 decoration-site-accent font-light uppercase block"
                        >
                          {edge?.link?.title}
                        </LosseLink>
                      </li>
                    )
                  })}
                </ul>

                <ul className="flex flex-col mb-7">
                  {header?.sidemenu?.menuitem?.map((edge: any, index: number) => (
                    <li key={index} onClick={() => setIsOpen(!isOpen)} className="mb-3 sm:mb-9">
                      <LosseLink
                        to={edge?.link?.url || '/'}
                        className="text-white hover:underline hover:underline-offset-8 decoration-site-accent text-xl font-light block"
                      >
                        {edge?.link?.title}
                      </LosseLink>
                    </li>
                  ))}
                </ul>

                <div className="w-full block relative lg:hidden">
                  <svg
                    className="absolute top-0 bottom-0 my-auto left-2"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_736_318)">
                      <path
                        d="M23.9998 22.5868L17.7378 16.3248C19.3644 14.3354 20.1642 11.7969 19.9716 9.23432C19.7791 6.67179 18.609 4.28129 16.7034 2.55728C14.7977 0.833269 12.3024 -0.0923492 9.73342 -0.0281174C7.16447 0.0361144 4.71848 1.08528 2.9014 2.90237C1.08431 4.71946 0.0351378 7.16545 -0.029094 9.7344C-0.0933258 12.3034 0.832292 14.7987 2.5563 16.7043C4.28031 18.61 6.67081 19.7801 9.23334 19.9726C11.7959 20.1651 14.3344 19.3654 16.3238 17.7388L22.5858 24.0008L23.9998 22.5868ZM9.99978 18.0008C8.41753 18.0008 6.87081 17.5316 5.55522 16.6525C4.23963 15.7735 3.21425 14.524 2.60875 13.0622C2.00324 11.6004 1.84482 9.99188 2.1535 8.44004C2.46218 6.88819 3.22411 5.46272 4.34293 4.3439C5.46175 3.22508 6.88721 2.46316 8.43906 2.15448C9.99091 1.84579 11.5994 2.00422 13.0612 2.60972C14.5231 3.21522 15.7725 4.2406 16.6515 5.5562C17.5306 6.87179 17.9998 8.41851 17.9998 10.0008C17.9974 12.1218 17.1538 14.1552 15.654 15.655C14.1542 17.1548 12.1208 17.9984 9.99978 18.0008Z"
                        className="fill-white"
                      />
                    </g>
                  </svg>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault()
                        setIsOpen(!isOpen)
                        navigate(`/zoeken?q=${e.currentTarget.value}`)
                      }
                    }}
                    className={clsx(
                      'text-white focus:border-white border-white',
                      'w-full font-light h-10 pl-11 placeholder:text-[#A4A4A4] pr-3 py-2 text-base  placeholder-white bg-transparent border-b focus:outline-none'
                    )}
                    placeholder="IK ZOEK..."
                  />
                </div>
              </div>

              <AnimatePresence>
                {activeHeaderMenu?.submenu && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                    className="h-full w-full relative pt-4"
                  >
                    <div role="button" onClick={() => setIsSubmenuOpen(-1)} className="w-full absolute -top-11 -left-4">
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.9604 19.9948L5.73294 11.7673C5.26426 11.2984 5.00098 10.6627 5.00098 9.99977C5.00098 9.33686 5.26426 8.70109 5.73294 8.23227L13.9554 0.00976562L15.1338 1.1881L6.91128 9.4106C6.75505 9.56687 6.66729 9.7788 6.66729 9.99977C6.66729 10.2207 6.75505 10.4327 6.91128 10.5889L15.1388 18.8164L13.9604 19.9948Z"
                          fill="#393C42"
                        />
                      </svg>
                    </div>
                    <div className="relative">
                      <div className="relative max-h-[90dvh] max-lg:pb-20 overflow-scroll">
                        <ul>
                          {activeHeaderMenu?.submenu?.map((edge: any, index: number) => (
                            <li key={index} onClick={() => setIsOpen(!isOpen)} className="mb-5">
                              <LosseLink to={edge?.link?.url || '/'} className="text-site-base text-xl uppercase font-light block">
                                {edge?.link?.title}
                              </LosseLink>
                            </li>
                          ))}
                        </ul>
                      </div>
                      {activeHeaderMenu?.submenu.length > 7 && (
                        <div className="pointer-events-none lg:hidden block absolute w-full bottom-0 left-0 h-40 bg-gradient-to-t from-white to-transparent" />
                      )}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>

            <div className="hidden containerize lg:flex items-center h-full w-full bg-site-base">
              <div
                role="button"
                aria-label="Close menu"
                onClick={() => setIsOpen(!isOpen)}
                className="z-10 gap-2 h-10 min-w-[unset] flex items-center text-center absolute left-[114px] top-10 hover:underline hover:underline-offset-8 decoration-site-accent"
              >
                <div>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_736_329)">
                      <path
                        d="M24 1.414L22.586 0L12 10.586L1.414 0L0 1.414L10.586 12L0 22.586L1.414 24L12 13.414L22.586 24L24 22.586L13.414 12L24 1.414Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                </div>
                <div className="text-white text-lg font-semibold normal-case sm:block hidden">Menu</div>
              </div>

              <div className="w-1/3 h-full flex flex-col justify-center bg-site-base transition-all">
                <div className="h-1/2 lg:max-h-[461px] pl-12 xl:pl-36 transition-all">
                  <ul className="flex flex-col h-full justify-between">
                    {header?.menuHeader?.map((edge: any, index: number) => {
                      return edge?.submenu?.length > 0 ? (
                        <li key={index} onClick={() => setIsSubmenuOpen(index)} className="cursor-pointer">
                          <p
                            className={clsx(
                              activeHeaderMenu?.submenu ? 'text-2xl' : 'text-3xl',
                              'transition-all text-white font-light group relative uppercase flex'
                            )}
                          >
                            <div className="flex items-center gap-2">
                              <div className="relative">
                                {edge?.link?.title}
                                <div
                                  className={clsx(
                                    isSubmenuOpen === index && 'w-full',
                                    'aria-current-page:w-full',
                                    'absolute group-hover:w-full transition-all duration-200 w-0 left-0 -bottom-1 h-[2px] bg-site-accent'
                                  )}
                                />
                              </div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16">
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8L4.646 2.354a.5.5 0 0 1 0-.708"
                                />
                              </svg>
                            </div>
                          </p>
                        </li>
                      ) : (
                        <li key={index} onClick={() => setIsOpen(!isOpen)}>
                          <LosseLink
                            to={edge?.link?.url || '/'}
                            className={clsx(
                              activeHeaderMenu?.submenu ? 'text-2xl' : 'text-3xl',
                              'transition-all text-white font-light group uppercase flex'
                            )}
                          >
                            <div className="relative">
                              {edge?.link?.title}
                              <div
                                className={clsx(
                                  isSubmenuOpen === index && 'w-full',
                                  'aria-current-page:w-full',
                                  'absolute group-hover:w-full transition-all duration-200 w-0 left-0 -bottom-1 h-[2px] bg-site-accent'
                                )}
                              />
                            </div>
                          </LosseLink>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              <AnimatePresence mode="wait">
                {activeHeaderMenu?.submenu ? (
                  <motion.div
                    className="w-1/3 h-full flex flex-col justify-center bg-white relative"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="max-xl:mt-10 max-xl:ml-[63px] xl:absolute xl:left-[63px] xl:top-10 flex items-center gap-8">
                      <button type="button" onClick={() => setIsSubmenuOpen(-1)} className="flex items-center">
                        <svg width="13" height="24" viewBox="0 0 13 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="01 align center">
                            <path
                              id="Vector"
                              d="M10.7518 23.9942L0.878848 14.1212C0.316434 13.5586 0.000488281 12.7957 0.000488281 12.0002C0.000488281 11.2047 0.316434 10.4418 0.878848 9.87921L10.7458 0.012207L12.1598 1.42621L2.29285 11.2932C2.10538 11.4807 2.00006 11.735 2.00006 12.0002C2.00006 12.2654 2.10538 12.5197 2.29285 12.7072L12.1658 22.5802L10.7518 23.9942Z"
                              fill="#393C42"
                            />
                          </g>
                        </svg>
                        <span className="ml-2 text-lg font-semibold text-site-base">Terug</span>
                      </button>
                    </div>
                    <div
                      className={clsx(
                        isSubmenuOpen === 3 ? `pl-8 xl:pl-[55px] min-h-[400px] relative` : `pl-16 xl:pl-[110px] min-h-[461px]`,
                        'pr-4 border-r border-site-base'
                      )}
                    >
                      {isSubmenuOpen === 3 && (
                        <div className="absolute h-10 left-0 bottom-0 w-full bg-gradient-to-b from-transparent to-white z-10 pointer-events-none" />
                      )}
                      <div className={clsx(isSubmenuOpen === 3 ? 'max-xl:mt-10 max-2xl:h-[70vh] max-2xl:overflow-auto' : 'max-xl:mt-10')}>
                        <ul
                          key={`ul-${isSubmenuOpen}`}
                          className={clsx(
                            'flex animate-fade-left animate-ease-in-out gap-y-2 animate-duration-300 flex-col h-full justify-between '
                          )}
                        >
                          {activeHeaderMenu?.submenu?.map((edge: any, index: number) => (
                            <li key={index} onClick={() => setIsOpen(!isOpen)}>
                              <LosseLink
                                to={edge?.link?.url || '/'}
                                className={clsx('text-site-base uppercase font-light group flex text-base xl:text-lg')}
                              >
                                <div className="relative">
                                  {edge?.link?.title}
                                  <div
                                    className={clsx(
                                      'group-aria-current-page:w-full',
                                      'absolute group-hover:w-full transition-all duration-200 w-0 left-0 -bottom-1 h-[2px] bg-site-accent'
                                    )}
                                  />
                                </div>
                              </LosseLink>
                            </li>
                          ))}
                          <li>&nbsp;</li>
                        </ul>
                      </div>
                    </div>
                  </motion.div>
                ) : (
                  <div className="w-1/3 h-full bg-site-base flex items-center">
                    <div className="w-full min-h-[461px] border-r border-white" />
                  </div>
                )}
              </AnimatePresence>
              <div className={clsx(activeHeaderMenu?.submenu ? 'bg-white' : 'bg-site-base', 'w-1/3 h-full flex flex-col justify-center')}>
                <div className="h-1/2 relative lg:max-h-[461px] pl-16 xl:pl-[89px] pr-16 xl:pr-[116px]">
                  <ul className="flex relative flex-col h-full justify-between xl:py-8">
                    {header?.sidemenu?.menuitem?.map((edge: any, index: number) => (
                      <li key={index} onClick={() => setIsOpen(!isOpen)}>
                        <LosseLink
                          to={edge?.link?.url || '/'}
                          className={clsx(activeHeaderMenu?.submenu ? 'text-site-base' : 'text-white', 'text-xl font-light group flex')}
                        >
                          <div className="relative">
                            {edge?.link?.title}
                            <div
                              className={clsx(
                                'group-aria-current-page:w-full',
                                'absolute group-hover:w-full transition-all duration-200 w-0 left-0 -bottom-1 h-[2px] bg-site-accent'
                              )}
                            />
                          </div>
                        </LosseLink>
                      </li>
                    ))}
                    <LosseLink to={header.sidemenu?.imagelink?.url} className="relative group">
                      <LossePlaatjie
                        maxwidth={1000}
                        src={header?.sidemenu?.image || ''}
                        className="w-full rounded-[10px] h-full group-hover:opacity-50 opacity-80 transition-all duration-200 object-cover"
                      />
                      <div className="absolute bottom-3 flex items-center gap-3 left-3 text-xl 2xl:text-3xl text-white text-shadow-site-shadow font-semibold">
                        {header.sidemenu?.imagelink?.title}
                        <img src="/icons/pijl.svg" alt="pijl" className="2xl:w-[32px] w-[26px]" />
                      </div>
                    </LosseLink>

                    <div className="absolute w-full -bottom-32 lg:block hidden">
                      <svg
                        className="absolute top-0 bottom-0 my-auto left-2"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_736_318)">
                          <path
                            d="M23.9998 22.5868L17.7378 16.3248C19.3644 14.3354 20.1642 11.7969 19.9716 9.23432C19.7791 6.67179 18.609 4.28129 16.7034 2.55728C14.7977 0.833269 12.3024 -0.0923492 9.73342 -0.0281174C7.16447 0.0361144 4.71848 1.08528 2.9014 2.90237C1.08431 4.71946 0.0351378 7.16545 -0.029094 9.7344C-0.0933258 12.3034 0.832292 14.7987 2.5563 16.7043C4.28031 18.61 6.67081 19.7801 9.23334 19.9726C11.7959 20.1651 14.3344 19.3654 16.3238 17.7388L22.5858 24.0008L23.9998 22.5868ZM9.99978 18.0008C8.41753 18.0008 6.87081 17.5316 5.55522 16.6525C4.23963 15.7735 3.21425 14.524 2.60875 13.0622C2.00324 11.6004 1.84482 9.99188 2.1535 8.44004C2.46218 6.88819 3.22411 5.46272 4.34293 4.3439C5.46175 3.22508 6.88721 2.46316 8.43906 2.15448C9.99091 1.84579 11.5994 2.00422 13.0612 2.60972C14.5231 3.21522 15.7725 4.2406 16.6515 5.5562C17.5306 6.87179 17.9998 8.41851 17.9998 10.0008C17.9974 12.1218 17.1538 14.1552 15.654 15.655C14.1542 17.1548 12.1208 17.9984 9.99978 18.0008Z"
                            className={clsx(activeHeaderMenu?.submenu ? 'fill-site-base' : 'fill-white')}
                          />
                        </g>
                      </svg>
                      <input
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            setIsOpen(!isOpen)
                            navigate(`/zoeken?q=${e.currentTarget.value}`)
                          }
                        }}
                        className={clsx(
                          activeHeaderMenu?.submenu
                            ? 'text-site-base focus:border-site-base border-site-base'
                            : 'text-white focus:border-white border-white',
                          'w-full font-light h-10 pl-11 placeholder:text-[#A4A4A4] pr-3 py-2 text-base  placeholder-white bg-transparent border-b focus:outline-none'
                        )}
                        placeholder="IK ZOEK..."
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

export default HeaderMega
