import { useEffect, useState } from 'react'
import { Form, useNavigate, LosseLink, useLoaderData } from '@ubo/losse-sjedel'
import type { HeaderLoaderData } from './Header'
import { motion } from 'framer-motion'
import clsx from 'clsx'

export default function Menu() {
  const { header } = useLoaderData<HeaderLoaderData>()
  const [isSearchOpen, setSearchOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSearchOpen) {
      // @ts-ignore
      document.querySelector('#search-input')?.focus()
    }
  }, [isSearchOpen])

  return (
    <>
      <div className="relative mx-3" role="button" onClick={() => setSearchOpen(!isSearchOpen)}>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="01 align center">
            <path
              id="Vector"
              d="M23.9998 22.5868L17.7378 16.3248C19.3644 14.3354 20.1642 11.7969 19.9716 9.23432C19.7791 6.67179 18.609 4.28129 16.7034 2.55728C14.7977 0.833269 12.3024 -0.0923492 9.73342 -0.0281174C7.16447 0.0361144 4.71848 1.08528 2.9014 2.90237C1.08431 4.71946 0.0351378 7.16545 -0.029094 9.7344C-0.0933258 12.3034 0.832292 14.7987 2.5563 16.7043C4.28031 18.61 6.67081 19.7801 9.23334 19.9726C11.7959 20.1651 14.3344 19.3654 16.3238 17.7388L22.5858 24.0008L23.9998 22.5868ZM9.99978 18.0008C8.41753 18.0008 6.87081 17.5316 5.55522 16.6525C4.23963 15.7735 3.21425 14.524 2.60875 13.0622C2.00324 11.6004 1.84482 9.99188 2.1535 8.44004C2.46218 6.88819 3.22411 5.46272 4.34293 4.3439C5.46175 3.22508 6.88721 2.46316 8.43906 2.15448C9.99091 1.84579 11.5994 2.00422 13.0612 2.60972C14.5231 3.21522 15.7725 4.2406 16.6515 5.5562C17.5306 6.87179 17.9998 8.41851 17.9998 10.0008C17.9974 12.1218 17.1538 14.1552 15.654 15.655C14.1542 17.1548 12.1208 17.9984 9.99978 18.0008Z"
              fill="white"
            />
          </g>
        </svg>
      </div>
      {!isSearchOpen ? (
        header?.menuRight?.map((edge: any, index: number) => {
          return (
            <li className={clsx('relative mx-3', index === 1 && 'max-xl:hidden')} key={index}>
              <div className="flex flex-wrap items-center">
                <LosseLink className="text-xl group relative font-semibold text-white lg:text-lg px-2" to={edge?.link?.url || '/'}>
                  {edge?.link?.title}

                  <div className="absolute group-aria-current-page:w-[94%] group-hover:w-[94%] transition-all duration-200 w-0 left-[3%] -bottom-1 h-[2px] bg-site-accent" />
                </LosseLink>
              </div>
            </li>
          )
        })
      ) : (
        <Form className="lg:block hidden">
          <motion.div
            initial={{ x: '100%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '100%', opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex flex-wrap items-center relative"
          >
            <input
              id="search-input"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  navigate(`/zoeken?q=${e.currentTarget.value}`)
                }
              }}
              className="w-full min-w-[300px] placeholder:text-[#A4A4A4] h-10 px-3 py-2 text-base text-white placeholder-white bg-transparent border-b border-white focus:outline-none focus:border-white"
              placeholder="IK ZOEK..."
            />
            <svg
              className="absolute top-0 bottom-0 my-auto right-2 cursor-pointer"
              onClick={() => setSearchOpen(!isSearchOpen)}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                className="fill-white"
                d="m12 13.4l-4.9 4.9q-.275.275-.7.275t-.7-.275q-.275-.275-.275-.7t.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7t.275-.7q.275-.275.7-.275t.7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275t.7.275q.275.275.275.7t-.275.7L13.4 12l4.9 4.9q.275.275.275.7t-.275.7q-.275.275-.7.275t-.7-.275z"
              />
            </svg>
          </motion.div>
        </Form>
      )}
    </>
  )
}
