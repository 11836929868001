import { type LoaderData, useLosseLayout } from '@ubo/losse-sjedel'
import { LosseLink } from '@ubo/losse-sjedel'
import clsx from 'clsx'
import type { Component_Header } from '~/graphql/types'
import HeaderMega from './Mega/HeaderMega'
import HeaderDesktop from './HeaderDesktop'

export interface HeaderLoaderData extends Omit<LoaderData, 'header'> {
  header: Component_Header
}

export default function Header() {
  const { isSticky } = useLosseLayout()

  return (
    <nav
      data-component="Header"
      className={clsx(isSticky && 'shadow', 'fixed left-0 top-0 right-0 z-50 h-[75px] 2xl:h-[102px] bg-site-base transition-all')}
    >
      <div className="grid grid-cols-2 lg:grid-cols-3 container h-full items-center justify-between">
        <div className="xs:col-span-1">
          <HeaderMega />
        </div>
        <div className="xs:col-span-1 md:flex md:justify-center">
          <LosseLink to="/" aria-label="Home">
            <svg
              width="293"
              height="52"
              viewBox="0 0 293 52"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-[250px] 2xl:w-[293px] max-w-full"
            >
              <path
                d="M68.8516 15.6016H72.4541V28.8299L77.9112 22.8932H82.1369L76.0187 29.5111L82.821 36.4061H78.5952L72.4541 30.155V36.4061H68.8516V15.6016Z"
                fill="white"
              />
              <path
                d="M84.7515 35.5451C83.7026 34.6916 83.1782 33.5387 83.1782 32.0939C83.1782 30.5966 83.6722 29.4213 84.6603 28.5753C85.6483 27.7294 87.0392 27.3101 88.8329 27.3101C90.5657 27.3101 92.063 27.6545 93.3171 28.3507V27.9839C93.3171 27.0256 93.0207 26.3294 92.4202 25.9027C91.8198 25.4685 90.8546 25.2514 89.5169 25.2514C88.6809 25.2514 87.8524 25.3337 87.0316 25.4909C86.2107 25.6482 85.4887 25.8727 84.8579 26.1497V23.3648C85.4279 23.1028 86.1956 22.8857 87.1608 22.7135C88.1184 22.5488 89.0533 22.459 89.9501 22.459C92.329 22.459 94.0923 22.9606 95.2323 23.9637C96.3724 24.9669 96.95 26.3594 96.95 28.1411V36.391H93.5527V35.066C92.9598 35.6274 92.291 36.0616 91.5386 36.3611C90.7862 36.6605 89.8665 36.8103 88.7797 36.8103C87.138 36.8252 85.7927 36.3985 84.7515 35.5451ZM91.835 33.7708C92.481 33.4639 92.975 33.0372 93.3171 32.4907V30.9934C92.2682 30.2597 91.0674 29.8929 89.7145 29.8929C87.7916 29.8929 86.8264 30.6266 86.8264 32.0864C86.8264 32.7976 87.0696 33.3366 87.556 33.6885C88.0424 34.0403 88.7417 34.22 89.6537 34.22C90.4593 34.235 91.189 34.0778 91.835 33.7708Z"
                fill="white"
              />
              <path
                d="M99.4427 22.8934H102.931V24.72C103.577 23.9489 104.352 23.38 105.249 23.0206C106.146 22.6538 107.089 22.4741 108.077 22.4741C109.924 22.4741 111.307 22.9682 112.219 23.9489C113.131 24.9371 113.587 26.2397 113.587 27.8792V36.4062H109.984V28.2086C109.984 26.2397 109.049 25.2515 107.187 25.2515C106.367 25.2515 105.599 25.4462 104.869 25.828C104.147 26.2098 103.539 26.7862 103.038 27.5573V36.3987H99.4351V22.8934H99.4427Z"
                fill="white"
              />
              <path
                d="M117.889 35.5827C117.106 34.7592 116.718 33.5913 116.718 32.0941V25.5959H114.659V22.8934H116.718V18.8433H120.321V22.8934H123.604V25.5959H120.321V31.6449C120.321 32.5058 120.481 33.1197 120.807 33.4865C121.134 33.8533 121.719 34.033 122.578 34.033C123.171 34.033 123.749 33.8983 124.319 33.6362V36.3388C123.498 36.6607 122.502 36.8179 121.317 36.8179C119.819 36.8254 118.672 36.4137 117.889 35.5827Z"
                fill="white"
              />
              <path
                d="M128.81 35.9644C127.602 35.3954 126.66 34.5645 125.998 33.4864C125.33 32.4084 124.995 31.1282 124.995 29.6459C124.995 28.2011 125.322 26.9359 125.983 25.8579C126.644 24.7798 127.579 23.9414 128.795 23.3499C130.011 22.7585 131.425 22.4666 133.021 22.4666C134.625 22.4666 136.023 22.766 137.239 23.3499C138.448 23.9414 139.39 24.7798 140.051 25.8579C140.72 26.9359 141.055 28.2011 141.055 29.6459C141.055 31.1282 140.72 32.4084 140.051 33.4864C139.383 34.5645 138.448 35.3879 137.239 35.9644C136.031 36.5334 134.625 36.8253 133.021 36.8253C131.425 36.8253 130.019 36.5334 128.81 35.9644ZM136.168 32.8127C136.95 31.9966 137.338 30.9411 137.338 29.6459C137.338 28.2759 136.95 27.2054 136.183 26.4193C135.408 25.6408 134.359 25.2515 133.021 25.2515C131.691 25.2515 130.635 25.6408 129.867 26.4193C129.092 27.1979 128.712 28.2759 128.712 29.6459C128.712 30.9411 129.099 31.9966 129.882 32.8127C130.665 33.6287 131.714 34.0404 133.029 34.0404C134.336 34.0404 135.385 33.6287 136.168 32.8127Z"
                fill="white"
              />
              <path
                d="M146.147 35.9644C144.938 35.3954 143.996 34.5645 143.335 33.4864C142.666 32.4084 142.332 31.1282 142.332 29.6459C142.332 28.2011 142.658 26.9359 143.32 25.8579C143.981 24.7798 144.916 23.9414 146.132 23.3499C147.348 22.7585 148.761 22.4666 150.357 22.4666C151.961 22.4666 153.36 22.766 154.576 23.3499C155.784 23.9414 156.727 24.7798 157.388 25.8579C158.057 26.9359 158.391 28.2011 158.391 29.6459C158.391 31.1282 158.057 32.4084 157.388 33.4864C156.719 34.5645 155.784 35.3879 154.576 35.9644C153.367 36.5334 151.961 36.8253 150.357 36.8253C148.761 36.8253 147.355 36.5334 146.147 35.9644ZM153.504 32.8127C154.287 31.9966 154.674 30.9411 154.674 29.6459C154.674 28.2759 154.287 27.2054 153.519 26.4193C152.744 25.6408 151.695 25.2515 150.357 25.2515C149.027 25.2515 147.971 25.6408 147.203 26.4193C146.428 27.1979 146.048 28.2759 146.048 29.6459C146.048 30.9411 146.436 31.9966 147.219 32.8127C148.001 33.6287 149.05 34.0404 150.365 34.0404C151.672 34.0404 152.721 33.6287 153.504 32.8127Z"
                fill="white"
              />
              <path
                d="M160.352 22.8933H163.841V25.0569C164.395 24.3232 165.178 23.7842 166.197 23.4248C167.215 23.0655 168.386 22.8933 169.7 22.8933V25.5959C168.31 25.5959 167.101 25.8055 166.083 26.2322C165.064 26.6514 164.357 27.3177 163.955 28.2161V36.4061H160.352V22.8933Z"
                fill="white"
              />
              <path
                d="M171.456 19.9661C171.068 19.5918 170.871 19.1201 170.871 18.5587C170.871 17.9972 171.068 17.5255 171.456 17.1363C171.843 16.7545 172.33 16.5598 172.9 16.5598C173.47 16.5598 173.956 16.7545 174.359 17.1363C174.762 17.5181 174.96 17.9972 174.96 18.5587C174.96 19.1201 174.762 19.5918 174.359 19.9661C173.956 20.3404 173.478 20.5276 172.9 20.5276C172.33 20.5276 171.843 20.3404 171.456 19.9661ZM171.068 22.8932H174.724V36.4061H171.068V22.8932Z"
                fill="white"
              />
              <path
                d="M177.445 22.8934H180.934V24.72C181.58 23.9489 182.355 23.38 183.252 23.0206C184.149 22.6538 185.091 22.4741 186.079 22.4741C187.926 22.4741 189.309 22.9682 190.221 23.9489C191.133 24.9371 191.589 26.2397 191.589 27.8792V36.4062H187.987V28.2086C187.987 26.2397 187.052 25.2515 185.19 25.2515C184.369 25.2515 183.601 25.4462 182.872 25.828C182.15 26.2098 181.542 26.7862 181.04 27.5573V36.3987H177.438L177.445 22.8934Z"
                fill="white"
              />
              <path
                d="M194.097 22.8933H197.586V25.0569C198.141 24.3232 198.923 23.7842 199.942 23.4248C200.96 23.0655 202.131 22.8933 203.446 22.8933V25.5959C202.055 25.5959 200.846 25.8055 199.828 26.2322C198.809 26.6514 198.103 27.3177 197.7 28.2161V36.4061H194.097V22.8933Z"
                fill="white"
              />
              <path
                d="M205.209 19.9661C204.822 19.5918 204.624 19.1201 204.624 18.5587C204.624 17.9972 204.822 17.5255 205.209 17.1363C205.597 16.7545 206.083 16.5598 206.653 16.5598C207.223 16.5598 207.71 16.7545 208.113 17.1363C208.515 17.5181 208.713 17.9972 208.713 18.5587C208.713 19.1201 208.515 19.5918 208.113 19.9661C207.71 20.3404 207.231 20.5276 206.653 20.5276C206.076 20.5276 205.597 20.3404 205.209 19.9661ZM204.822 22.8932H208.477V36.4061H204.822V22.8932Z"
                fill="white"
              />
              <path
                d="M214.322 35.9794C213.113 35.4179 212.171 34.6019 211.51 33.5313C210.841 32.4608 210.506 31.1731 210.506 29.6759C210.506 28.1786 210.841 26.8835 211.517 25.8055C212.194 24.7274 213.136 23.9039 214.345 23.3275C215.553 22.7585 216.967 22.4666 218.585 22.4666C220.721 22.4666 222.424 22.8783 223.701 23.7018V26.5466C222.462 25.6857 220.881 25.2515 218.958 25.2515C217.491 25.2515 216.321 25.6108 215.462 26.337C214.595 27.0632 214.162 28.1711 214.162 29.6759C214.162 31.1582 214.595 32.2587 215.462 32.9699C216.328 33.6811 217.476 34.0404 218.905 34.0404C220.828 34.0404 222.484 33.5912 223.875 32.6854V35.5002C223.191 35.9494 222.416 36.2863 221.55 36.4959C220.683 36.713 219.68 36.8178 218.532 36.8178C216.936 36.8253 215.53 36.5408 214.322 35.9794Z"
                fill="white"
              />
              <path
                d="M225.966 15.6016H229.568V24.6076C230.86 23.1852 232.54 22.4665 234.6 22.4665C236.446 22.4665 237.83 22.9606 238.742 23.9413C239.654 24.9295 240.11 26.2321 240.11 27.8716V36.3986H236.507V28.2085C236.507 26.2396 235.572 25.2514 233.71 25.2514C232.89 25.2514 232.122 25.4461 231.392 25.8279C230.67 26.2097 230.062 26.7861 229.561 27.5572V36.3986H225.958L225.966 15.6016Z"
                fill="white"
              />
              <path
                d="M244.419 35.5827C243.636 34.7592 243.249 33.5913 243.249 32.0941V25.5959H241.189V22.8934H243.249V18.8433H246.851V22.8934H250.135V25.5959H246.851V31.6449C246.851 32.5058 247.011 33.1197 247.338 33.4865C247.664 33.8533 248.25 34.033 249.108 34.033C249.701 34.033 250.279 33.8983 250.849 33.6362V36.3388C250.028 36.6607 249.032 36.8179 247.847 36.8179C246.342 36.8254 245.202 36.4137 244.419 35.5827Z"
                fill="white"
              />
              <path
                d="M252.734 19.9661C252.347 19.5918 252.149 19.1201 252.149 18.5587C252.149 17.9972 252.347 17.5255 252.734 17.1363C253.122 16.7545 253.608 16.5598 254.178 16.5598C254.748 16.5598 255.235 16.7545 255.637 17.1363C256.04 17.5181 256.238 17.9972 256.238 18.5587C256.238 19.1201 256.04 19.5918 255.637 19.9661C255.235 20.3404 254.756 20.5276 254.178 20.5276C253.608 20.5276 253.122 20.3404 252.734 19.9661ZM252.347 22.8932H256.002V36.4061H252.347V22.8932Z"
                fill="white"
              />
              <path
                d="M258.723 22.8934H262.211V24.72C262.858 23.9489 263.633 23.38 264.53 23.0206C265.426 22.6613 266.369 22.4741 267.357 22.4741C269.204 22.4741 270.587 22.9682 271.499 23.9489C272.411 24.9371 272.867 26.2397 272.867 27.8792V36.4062H269.265V28.2086C269.265 26.2397 268.33 25.2515 266.468 25.2515C265.647 25.2515 264.879 25.4462 264.15 25.828C263.428 26.2098 262.82 26.7862 262.318 27.5573V36.3987H258.715L258.723 22.8934Z"
                fill="white"
              />
              <path
                d="M279.776 42.6197C278.864 42.4475 278.096 42.223 277.488 41.946V39.1311C278.97 39.7674 280.65 40.0894 282.52 40.0894C284.199 40.0894 285.4 39.745 286.138 39.0488C286.867 38.3525 287.24 37.3194 287.24 35.9494V35.0735C286.594 35.6575 285.818 36.0917 284.921 36.3836C284.025 36.6756 283.067 36.8178 282.033 36.8178C280.642 36.8178 279.388 36.5109 278.264 35.9045C277.139 35.2981 276.265 34.4447 275.634 33.3592C275.003 32.2736 274.691 31.0234 274.691 29.616C274.691 28.1936 275.003 26.9359 275.619 25.8579C276.242 24.7798 277.093 23.9414 278.188 23.3499C279.282 22.7585 280.528 22.4666 281.919 22.4666C283.082 22.4666 284.085 22.6238 284.921 22.9307C285.757 23.2376 286.571 23.7542 287.354 24.4654V22.8858H290.842V35.5826C290.842 37.9108 290.158 39.7076 288.798 40.9727C287.437 42.2379 285.347 42.8743 282.527 42.8743C281.608 42.8743 280.696 42.7919 279.776 42.6197ZM285.567 33.2094C286.381 32.6554 286.936 31.9218 287.24 31.001V28.2685C286.913 27.3476 286.358 26.614 285.552 26.075C284.754 25.5285 283.835 25.259 282.809 25.259C281.516 25.259 280.445 25.6632 279.609 26.4717C278.773 27.2803 278.355 28.3283 278.355 29.6235C278.355 30.9186 278.773 31.9817 279.609 32.8052C280.445 33.6287 281.516 34.0404 282.809 34.0404C283.835 34.0404 284.754 33.7634 285.567 33.2094Z"
                fill="white"
              />
              <path
                d="M19.6088 15.6016H2.69044C1.99121 15.6016 1.99121 20.0185 2.69044 20.0185H9.55354V35.9644C9.55354 36.6531 14.0377 36.6531 14.0377 35.9644V20.0185H17.1007C18.9932 20.0185 21.1592 15.6016 19.6088 15.6016Z"
                fill="#42BD3B"
              />
              <path
                d="M60.9393 31.9816L51.2489 31.9891C49.3564 31.9891 47.8211 30.4769 47.8211 28.6128V23.3948C47.8211 21.5756 49.288 20.0934 51.1197 20.026L62.1249 20.0185C62.8242 20.0185 62.8242 15.6016 62.1249 15.6016H51.2489H51.2413C46.8863 15.609 43.3369 19.0977 43.3369 23.3948V28.6128C43.3369 32.9099 46.8863 36.4061 51.2489 36.4061H58.4312C60.3313 36.3986 62.4897 31.9816 60.9393 31.9816Z"
                fill="#42BD3B"
              />
              <path
                d="M31.4651 31.9816H33.4031C35.5084 31.9816 36.9297 30.0725 36.7549 28.156C36.5801 26.2395 36.4661 24.9369 36.2761 23.2525C36.0709 21.3659 34.8548 20.0184 32.5215 20.0184H28.3033C25.97 20.0184 24.754 21.3659 24.5488 23.2525C24.3664 24.9369 24.2523 26.247 24.0699 28.156C23.8951 30.0725 25.3164 31.9816 27.4217 31.9816H29.3522V36.3985H27.3533C23.1047 36.3985 19.6313 32.9772 19.6313 28.7924V23.2001C19.6313 19.0152 23.1047 15.594 27.3533 15.594H33.4715C37.7201 15.594 41.1935 19.0152 41.1935 23.2001V28.7924C41.1935 32.9772 37.7201 36.3985 33.4715 36.3985H31.4575L31.4651 31.9816Z"
                fill="#42BD3B"
              />
            </svg>
          </LosseLink>
        </div>

        <div className="xs:col-span-1 hidden lg:flex items-center lg:justify-end">
          <HeaderDesktop />
        </div>
      </div>
    </nav>
  )
}
