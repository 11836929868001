import { useLoaderData } from '@ubo/losse-sjedel'
import { useEffect, useState } from 'react'
import type { Page } from '~/graphql/types'
import useScrollProgress from '~/hooks/useScrollProgress'

export default function ScrollProgress() {
  const { page } = useLoaderData<{ page: Page }>()
  const scrollprogress = useScrollProgress()

  const [progress, setProgress] = useState(0)

  const handleScroll = () => {
    const windowHeight = window.innerHeight
    const scrollHeight = document.documentElement.scrollHeight - windowHeight
    const scrolled = window.scrollY
    const progress = (scrolled / scrollHeight) * 100
    setProgress(progress)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!scrollprogress.includes(page.databaseId)) return null

  return (
    <div className="fixed z-[100] h-[6px] top-0 left-0 w-full">
      <div style={{ width: `${progress}%` }} className="bg-site-accent transition-all duration-0 h-full absolute left-0 right-0" />
    </div>
  )
}
